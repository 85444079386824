export const isValidMedicareNumber = (value: any): boolean => {
  if (!value || !value[0]) {
    return true;
  }

  const medicareNumberString = value[0].toString().replace(/ /g, "");
  if (
    !(
      /\d{10}/.test(medicareNumberString) &&
      medicareNumberString[0].match(/[2-6]/) !== null
    )
  ) {
    return false;
  }

  const PATTERN = /^(\d{8})(\d)/;
  const multipliers = [1, 3, 7, 9, 1, 3, 7, 9];

  if (medicareNumberString.length === 10) {
    const matches = PATTERN.exec(medicareNumberString);
    if (matches) {
      const base = matches[1];
      const checkDigit = matches[2];
      let total = 0;

      for (let i = 0; i < multipliers.length; i++) {
        total += Number(base[i]) * multipliers[i];
      }
      return total % 10 === Number(checkDigit);
    }
  }

  return false;
};
