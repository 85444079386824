import { useState } from "react";

import { CommandBar, ICommandBarItemProps } from "@fluentui/react";

import { ExportDialog } from "./ExportDialog";
import { testModels } from "./Forms";

const testMode = process.env.REACT_APP_TEST;
export interface PreviewHeaderProperties {
  setPreviewForm: (model: string) => void;
  children?: React.ReactNode;
}
export const PreviewHeader: React.FC<PreviewHeaderProperties> = ({
  children,
  setPreviewForm
}) => {
  const [exportData, setExport] = useState(false);

  const items: ICommandBarItemProps[] = testMode
    ? testModels.map(model => {
        return {
          name: model.name,
          key: model.name,
          model,
          onClick: () => setPreviewForm(JSON.stringify(model))
        };
      })
    : [];

  items.push({ name: "Export", key: "export", onClick: () => setExport(true) });

  return (
    <div>
      {testMode && <CommandBar items={items}>{children}</CommandBar>}
      {testMode && exportData && (
        <ExportDialog onClose={() => setExport(false)} />
      )}
      {children}
    </div>
  );
};
