import * as React from "react";
import { ItemValue } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";

import { OptionsSelect } from "@bps/fluent-ui";
import { Label, Stack } from "@fluentui/react";

import { convertStringToOption, useFieldLookupApi } from "../hooks";
import { CustomSelectModel } from "./MultiTagBox";
import { registerWidget } from "./registerWidget";

class CustomPickerModel extends CustomSelectModel {
  getType() {
    return "picker";
  }

  get placeholder() {
    return this.getPropertyValue("placeholder");
  }
  set placeholder(val) {
    this.setPropertyValue("placeholder", val);
  }
}
export interface CustomPickerProps {
  choices: ItemValue[];
  value: string[];
  onSelected: (value: string[] | string) => void;
  fetchUrl: string;
  placeholder: string;
}
const Picker: React.FC<CustomPickerProps> = ({
  fetchUrl,
  choices,
  value,
  onSelected,
  placeholder
}) => {
  const { data, error } = useFieldLookupApi(fetchUrl);

  return (
    <>
      <OptionsSelect
        disabled={false}
        onChangeSelectedKeys={selected => selected && onSelected(selected)}
        options={data ?? choices}
        styles={{ fieldContent: { justifyContent: "flex-start" } }}
        selectedKeys={value ?? []}
        placeholder={placeholder}
        initialNumOfOptions={(data ?? choices).length > 50 ? 15 : undefined}
        preventDismissOnEvent={e => e.type === "resize" || e.type === "scroll"}
      />

      {error && <Label>Error loading</Label>}
    </>
  );
};

export const CustomPicker: React.FC<CustomPickerProps> = ({
  choices,
  value,
  onSelected,
  fetchUrl,
  placeholder
}) => {
  const { data, error } = useFieldLookupApi(fetchUrl);
  const rows = data ? data : choices.map(x => convertStringToOption(x.value));
  return (
    <Stack>
      {!error ? (
        <Picker
          choices={rows}
          value={value}
          onSelected={onSelected}
          fetchUrl={fetchUrl}
          placeholder={placeholder}
        />
      ) : (
        <Label>Error loading</Label>
      )}
    </Stack>
  );
};

class CustomPickerQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = { value: this.question.value };
  }
  get question() {
    return this.questionBase as CustomPickerModel;
  }
  get value() {
    return this.question.value;
  }
  visibleChoices: string[] = [];
  get choices() {
    return this.visibleChoices;
  }
  set choices(value: string[]) {
    this.visibleChoices = value;
  }
  get placeholder() {
    return this.question.placeholder;
  }
  renderElement() {
    return (
      <>
        <CustomPicker
          choices={this.question.choices}
          onSelected={(selected: string[] | string) =>
            (this.question.value =
              selected.constructor === Array ? selected[0] : selected)
          }
          fetchUrl={this.question.fetchUrl}
          value={this.question.value}
          placeholder={this.question.placeholder ?? "Please select"}
        />
      </>
    );
  }
}
export const registerPicker = () =>
  registerWidget({
    baseQuestion: "checkbox",
    newQuestionName: "picker",
    renderer: CustomPickerQuestion,
    newModelFn: name => new CustomPickerModel(name),
    newToolboxItem: {
      name: "picker",
      title: "Picker",
      elementsJSON: {
        choices: [],
        value: [],
        visible: true
      },
      questionJSON: {
        type: "checkbox",
        renderAs: "picker"
      }
    },
    extraProperties: [
      {
        name: "choices:itemValue[]",
        category: "general",
        visibleIndex: 3 // Place after the Name, Title, and Color Picker Type
      },
      {
        name: "fetchUrl:string",
        category: "general",
        visibleIndex: 4 // Place after the Name, Title, and Color Picker Type
      },
      {
        name: "placeholder",
        category: "general",
        visibleIndex: 4 // Place after the Name, Title, and Color Picker Type
      }
    ]
  });
