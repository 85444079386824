import { FunctionComponent } from "react";

import { ProgressIndicator } from "@fluentui/react";

export const Loading: FunctionComponent = () => {
  return (
    <ProgressIndicator
      label="Loading Form"
      description="Loading Form. Please wait."
    />
  );
};
