import { FunctionComponent, useEffect } from "react";
import { SurveyModel } from "survey-core";
import { Survey } from "survey-react-ui";

import { CompletedSurvey } from "./CompletedSurvey";
import { setupCustomListFunctions, setupValidators } from "./CustomFunctions";
import { FormHeader } from "./FormHeader";
import { InvalidRequest } from "./InvalidRequest";
import { Loading } from "./Loading";
import { PreviewHeader } from "./Preview/PreviewHeader";
import { QrCode } from "./QrCode";
import { registerWidgets } from "./Widgets";
import { FormStatusEnum, useFormApi } from "./hooks";

const testMode = process.env.REACT_APP_TEST;

registerWidgets();
setupValidators();
setupCustomListFunctions();

export const SurveyRenderer: FunctionComponent = () => {
  const css = {
    title: "bp-title",
    description: "bp-description",
    titleOnAnswer: "bp-title-question-answered",
    page: "bp-page",
    question: {
      titleOnAnswer: "bp-title-question-answered",
      title: "bp-question-title",
      requiredText: "bp-required-text"
    }
  };

  const {
    form,
    status,
    expiry,
    description,
    helpText,
    titleFields,
    setDeveloperPreviewForm
  } = useFormApi();

  useEffect(() => {
    if (form) {
      form.onUpdateQuestionCssClasses.add((options: SurveyModel) => {
        const classes = options.cssClasses;
        classes.mainRoot = "bp-question-root";
        classes.title = "bp-question-title";
        classes.root += " bp-question-root";
        classes.item += " bp-question-item";
        classes.label += " bp-question-label";
        classes.error += " bp-question-error";
      });
    }
  }, [form]);

  const result = () => {
    switch (status) {
      case FormStatusEnum.Initialising:
        return <h3>Initialising</h3>;
      case FormStatusEnum.QrCode:
        return <QrCode />;
      case FormStatusEnum.Available:
        return <Survey model={form} css={css} />;
      case FormStatusEnum.Loading:
        return <Loading />;
      case FormStatusEnum.Completed:
        return <CompletedSurvey />;
      case FormStatusEnum.AlreadyCompleted:
      case FormStatusEnum.NotFound:
      case FormStatusEnum.Expired:
      case FormStatusEnum.DecodeError:
      default:
        return <InvalidRequest description={description} helpText={helpText} />;
    }
  };
  if (testMode) {
    return (
      <PreviewHeader setPreviewForm={setDeveloperPreviewForm}>
        {result()}
      </PreviewHeader>
    );
  }

  return (
    <>
      <FormHeader titleFields={titleFields} expiry={expiry} />
      {result()}
    </>
  );
};
