import { QuestionBooleanModel } from "survey-core";
import * as SurveyReact from "survey-react-ui";

import { ButtonsGroupChoice, ButtonsGroupOption, Stack } from "@bps/fluent-ui";
import { Label } from "@fluentui/react";

import { replaceWidget } from "./replaceWidget";

class CustomBoolean extends SurveyReact.SurveyQuestionBoolean {
  get trueValue() {
    return {
      key: true,
      text: this.question.labelTrue ?? "Yes",
      disabled: this.isDisplayMode
    };
  }

  get falseValue() {
    const result: ButtonsGroupOption<boolean> = {
      key: false,
      text: this.question.labelFalse ?? "No",
      disabled: this.isDisplayMode
    };
    return result;
  }

  get choices() {
    const x: ButtonsGroupOption<boolean>[] = [this.trueValue, this.falseValue];
    return x;
  }

  get selectedKeys() {
    if (typeof this.question.value === "boolean")
      return this.question.value ? [true] : [false];
    return [];
  }

  valueChanged(
    selectedKeys: boolean[] | boolean | undefined,
    question: QuestionBooleanModel
  ) {
    if (typeof selectedKeys === "boolean") {
      question.value = selectedKeys;
      return;
    }
    question.value =
      selectedKeys && selectedKeys.length ? selectedKeys[0] : undefined;
  }

  renderElement() {
    // Otherwise, render the React component
    return (
      <Stack>
        {this.question.label && <Label>{this.question.label}</Label>}
        <ButtonsGroupChoice<boolean>
          options={this.choices}
          value={this.selectedKeys}
          styles={{ label: { minHeight: "unset" } }}
          onChange={keys => {
            this.valueChanged(keys as [], this.question);
          }}
        />
      </Stack>
    );
  }
}

export const registerBooleanWidget = () =>
  replaceWidget({
    questionName: "boolean",
    renderer: CustomBoolean,
    model: name => new QuestionBooleanModel(name)
  });
