import { FC } from "react";
import { Question } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";

import { ITag, StaticMultiTagPicker } from "@bps/fluent-ui";

import { getFormFieldData } from "../api/form";
import { useFormId } from "../hooks";
import { registerWidget } from "./registerWidget";

const QUESTION_TYPE = "bpMultiTagBox";
// A model for the new question type
export class CustomSelectModel extends Question {
  constructor(props: string) {
    super(props);
    this.choices = [];
  }

  getType() {
    return QUESTION_TYPE;
  }

  get choices() {
    return this.getPropertyValue("choices");
  }

  set choices(val) {
    this.setPropertyValue("choices", val);
  }

  public get fetchUrl(): string {
    return this.getPropertyValue("fetchUrl");
  }

  public set fetchUrl(val: string) {
    this.setPropertyValue("fetchUrl", val);
  }
}
interface MultiTagPickerProps {
  fetchUrl: string;
  options: ITag[];
  value: string[];
  onSelectChange: (items?: string[]) => void;
}

export const MultiTagPicker: FC<MultiTagPickerProps> = ({
  fetchUrl,
  options,
  onSelectChange,
  value
}) => {
  const { formId } = useFormId();
  const getFields = async () => {
    const { data } = await getFormFieldData(formId, fetchUrl);
    const results: string[] = JSON.parse(data);
    const items: ITag[] = results.map(x => ({ key: x, name: x }));
    return items;
  };
  return (
    <>
      {(options || fetchUrl) && (
        <StaticMultiTagPicker
          selectedKeys={value}
          onChange={(_, selected) => onSelectChange(selected.map(x => x.name))}
          fetchDataSource={() =>
            fetchUrl ? getFields() : Promise.resolve(options)
          }
          pickerCalloutProps={{
            preventDismissOnEvent: e =>
              e.type === "resize" || e.type === "scroll"
          }}
        />
      )}
    </>
  );
};

class DropDownTagBoxQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = { value: this.question.value };
  }

  visibleChoices: string[] = [];

  get options() {
    const c = this.question.fetchUrl ? [] : this.question.choices ?? [];
    return c?.map((c: any) => {
      return { key: c.value, value: c.value, name: c.value };
    });
  }

  get question() {
    return this.questionBase as CustomSelectModel;
  }

  get value() {
    return this.question.value;
  }

  get choices() {
    return this.visibleChoices;
  }

  set choices(value: string[]) {
    this.visibleChoices = value;
  }

  // Retrieve an option object based on the question value
  get selectedOption() {
    const res = [];
    const opts = this.options;
    let qValue = this.question.value;
    if (!qValue) qValue = [];

    for (let i = 0; i < opts.length; i++) {
      if (qValue.indexOf(opts[i].value) > -1) res.push(opts[i]);
    }
    return res;
  }

  // Set the question value to the selected option value
  onSelectChange = (items?: any[]) => {
    this.question.value = items;
  };

  renderElement = () => (
    <MultiTagPicker
      fetchUrl={this.question.fetchUrl}
      onSelectChange={this.onSelectChange}
      options={this.options}
      value={this.question.value}
    />
  );
}

export const registerMultiTagBox = () =>
  registerWidget({
    baseQuestion: "checkbox",
    newQuestionName: QUESTION_TYPE,
    renderer: DropDownTagBoxQuestion,
    newModelFn: name => new CustomSelectModel(name),
    newToolboxItem: {
      name: QUESTION_TYPE,
      title: "Tag (Multi Tag)",
      elementsJSON: {
        choices: [],
        value: [],
        visible: true
      },
      questionJSON: {
        type: "checkbox",
        renderAs: QUESTION_TYPE
      }
    },
    extraProperties: [
      {
        name: "choices:itemValue[]",
        category: "general",
        visibleIndex: 3 // Place after the Name, Title, and Color Picker Type
      },
      {
        name: "fetchUrl:string",
        category: "general",
        visibleIndex: 4 // Place after the Name, Title, and Color Picker Type
      }
    ]
  });
