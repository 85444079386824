import { SpinNumberInput, SpinNumberInputProps } from "@bps/fluent-ui";

export interface INumberPicker extends SpinNumberInputProps {}

export const CustomNumberPicker: React.FC<INumberPicker> = props => {
  return (
    <SpinNumberInput
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      placeholder="ok"
      {...props}
    />
  );
};
