import { FunctionComponent } from "react";

import { Text, Stack, FontIcon } from "@fluentui/react";

export interface InvalidRequestProps {
  description?: string;
  helpText?: string;
}

export const CompletedSurvey: FunctionComponent = () => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      verticalFill
      styles={{
        root: {
          height: "100vh"
        }
      }}
    >
      <FontIcon
        iconName="Completed"
        className="bp-icon"
        style={{
          textAlign: "center",
          fontSize: 48,
          marginBottom: 16
        }}
      />
      <Text variant="xLarge" styles={{ root: { textAlign: "center" } }}>
        Thank you for updating your details.
      </Text>
      <Text
        variant="medium"
        styles={{
          root: { textAlign: "center", paddingTop: 12, maxWidth: 348 }
        }}
      >
        Please contact the practice if you have any questions.
      </Text>
    </Stack>
  );
};
