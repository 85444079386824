import { useLocation, useSearchParams } from "react-router-dom";

export const useFormId = () => {
  const { pathname } = useLocation();
  const [queryParameters] = useSearchParams();

  const qrcode = pathname.includes("/qrcode");
  const preview = pathname.includes("/preview");
  const maybeId = queryParameters.get("id") ?? "";
  const formId = maybeId.endsWith(".")
    ? maybeId.substring(0, maybeId.length - 1)
    : maybeId;
  return { formId, qrcode, preview };
};
