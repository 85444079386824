import { DateTime } from "luxon";
import { CompleteEvent, Model, SurveyModel } from "survey-core";

import { renderers } from "../Widgets/replaceWidget";
import { postForm } from "../api/form";
import { FormStatusEnum } from "./useFormApi";

class FormConfig {
  hideFields: string[] | undefined;
  titleFields?: Record<string, string> | undefined;
}
export const setupModel: (options: {
  modelJson: string;
  expiry?: DateTime;
  setFormStatus?: (data: any) => void;
  formConfig?: FormConfig;
  formId?: string;
  qrcode: boolean;
  preview: boolean;
}) => { form: Model; titleFields?: Record<string, string> } = ({
  modelJson,
  expiry,
  setFormStatus,
  formId,
  qrcode,
  formConfig,
  preview
}) => {
  const model = new Model(modelJson);

  const hideFields = formConfig && formConfig.hideFields;
  const titleFields =
    formConfig && formConfig.titleFields ? formConfig.titleFields : undefined;
  model.getAllQuestions().forEach(x => {
    const renderer = renderers.get(x.getType());
    x.renderAs = renderer ?? x.renderAs;
    if (hideFields && hideFields.find(h => h === x.name)) {
      x.visible = false;
    }
  });
  const onComplete: (
    sender: SurveyModel,
    options: CompleteEvent
  ) => void = async (survey, options) => {
    try {
      options.showDataSavingClear();
      const result =
        !preview && formId && (await postForm(formId, survey.data));
      if (!preview && setFormStatus) {
        if (!result) {
          formId && options.showDataSavingError();
        } else {
          !formId && alert(JSON.stringify(survey.data, undefined, 4));
          setFormStatus({ status: FormStatusEnum.Completed });
        }
      } else {
        alert(JSON.stringify(survey.data, undefined, 4));
      }
    } catch {
      options.showDataSavingError();
    }
  };
  const formStatus = FormStatusEnum.Available;
  const status = qrcode ? FormStatusEnum.QrCode : formStatus;
  model && model.onComplete.add((model, event) => onComplete(model, event));
  model &&
    setFormStatus &&
    setFormStatus({
      status,
      form: model,
      expiry,
      titleFields,
      preview
    });
  return { form: model, titleFields };
};
