import { useAsync } from "react-use";

import { getFormFieldData } from "../api/form";
import { useFormId } from "./useFormId";

export const useFieldApi = (fieldName?: string) => {
  const { formId } = useFormId();
  const { value, error, loading } = useAsync(
    async () => fieldName && getFormFieldData(formId, fieldName),
    [fieldName]
  );
  return { data: value?.data, error, loading };
};
