import { ValidationConstants } from "./constants";

export const checkNhiValues = (nhiAny: any): boolean => {
  let passTest = false;
  let checkValue = 0;
  const nhi = nhiAny[0] as string;
  if (!nhi) return true;
  for (let i = 0; i < 3; i++) {
    const letterNumeric =
      ValidationConstants.alphaTableForNzMOH.indexOf(nhi[i]) + 1;
    checkValue += letterNumeric * (7 - i);
  }

  if (new RegExp("[A-Z]{2}", "i").test(nhi.substring(5, 2))) {
    //new NHI format
    for (let i = 3; i < 5; i++) {
      checkValue += parseInt(nhi[i]) * (7 - i);
    }
    const letterNumeric =
      ValidationConstants.alphaTableForNzMOH.indexOf(nhi[5]) + 1;
    checkValue += letterNumeric * (7 - 5);

    const checkSum = checkValue % 24;

    // Verify the checkdigit but only if checksum is not 0
    if (checkSum !== 0) {
      const letterNumeric2 =
        ValidationConstants.alphaTableForNzMOH.indexOf(nhi[6]) + 1;
      if (letterNumeric2 === 24 - checkSum) {
        passTest = true;
      }
    }
  } else {
    // old NHI format
    for (let i = 3; i < 6; i++) {
      checkValue += parseInt(nhi[i]) * (7 - i);
    }

    const checkSum = checkValue % 11;

    // Verify the checkdigit but only if checksum is not 0
    if (checkSum !== 0) {
      if (parseInt(nhi[6]) === (11 - checkSum) % 10) {
        passTest = true;
      }
    }
  }
  return passTest;
};
