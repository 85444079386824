import { FC } from "react";
import { FunctionFactory } from "survey-core";

import { Dropdown, IDropdownOption } from "@bps/fluent-ui";

import { useFieldLookupApi } from "../hooks";
import { BpQuestionChoiceBase } from "./BpQuestionChoiceBase";
import { CustomSelectModel } from "./MultiTagBox";
import { replaceWidget } from "./replaceWidget";

export interface CustomDropDownControlProps {
  choices: IDropdownOption[];
  isDisplayMode: boolean;
  value: string;
  onChange: (value?: string) => void;
  fetchUrl: string;
  customListFunction: string | undefined;
}

export const CustomDropDownControl: FC<CustomDropDownControlProps> = ({
  isDisplayMode,
  choices,
  onChange,
  customListFunction,
  fetchUrl,
  value
}) => {
  const { data } = useFieldLookupApi(fetchUrl, (val: string) => ({
    key: val,
    text: val,
    data: val
  }));

  const options = (() => {
    if (data !== null && data !== undefined) {
      return data;
    }
    if (customListFunction) {
      return FunctionFactory.Instance.run(customListFunction, []);
    }
    return choices.map(x => ({ key: x.text, text: x.text, data: x.text }));
  })();

  return (
    <Dropdown
      name="Dropdown"
      disabled={isDisplayMode}
      options={options}
      onChange={(_, option: IDropdownOption<string> | undefined) => {
        onChange(option?.data);
      }}
      selectedKey={value?.toString()}
      calloutProps={{ calloutMaxHeight: 350 }}
    />
  );
};
class CustomDropDown extends BpQuestionChoiceBase {
  renderElement() {
    return (
      <CustomDropDownControl
        isDisplayMode={this.isDisplayMode}
        choices={this.question.choices.map(x => ({
          ...x,
          key: x.name,
          text: x.name
        }))}
        fetchUrl={this.question.fetchUrl}
        customListFunction={this.question.customListFunction}
        onChange={(selected?: string) => {
          this.question.value = selected;
        }}
        value={this.question.value}
      />
    );
  }
}

class CustomDropDownModel extends CustomSelectModel {
  getType() {
    return "dropdown";
  }
}

export const replaceDropDownWidget = () =>
  replaceWidget({
    questionName: "dropdown",
    renderer: CustomDropDown,
    model: name => new CustomDropDownModel(name),
    extraProperties: [
      {
        name: "choices:itemValue[]",
        category: "general",
        visibleIndex: 3 // Place after the Name, Title, and Color Picker Type
      },
      {
        name: "fetchUrl:string",
        category: "general",
        visibleIndex: 4 // Place after the Name, Title, and Color Picker Type
      },
      {
        name: "customListFunction:string",
        category: "general",
        visibleIndex: 5
      }
    ]
  });
