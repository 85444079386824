import * as React from "react";
import * as SurveyCore from "survey-core";
import * as SurveyReact from "survey-react-ui";

const { Serializer, ElementFactory, RendererFactory, ComponentCollection } =
  SurveyCore;
const { ReactQuestionFactory, SurveyQuestionElementBase } = SurveyReact;
export const registerWidget: (options: {
  baseQuestion: string;
  newQuestionName: string;
  renderer: typeof SurveyQuestionElementBase;
  newModelFn: (name: string) => any;
  newToolboxItem: any;
  extraProperties: any;
}) => void = ({
  baseQuestion,
  newQuestionName,
  renderer,
  newModelFn: model,
  extraProperties,
  newToolboxItem
}) => {
  ElementFactory.Instance.registerElement(newQuestionName, name => {
    return model(name);
  });
  ReactQuestionFactory.Instance.registerQuestion(newQuestionName, props =>
    React.createElement(renderer, props)
  );

  RendererFactory.Instance.registerRenderer(
    baseQuestion,
    newQuestionName,
    newQuestionName
  );
  ComponentCollection.Instance.add(
    newToolboxItem as SurveyCore.ICustomQuestionTypeConfiguration
  );

  Serializer.addClass(
    newQuestionName,
    extraProperties,
    function () {
      return model("");
    },
    "question"
  );
};
