import { DateTime } from "luxon";

import { IDropdownOption } from "@bps/fluent-ui";

export const medicareExpiryYears = (): IDropdownOption<string>[] => {
  const currentYear = DateTime.now().year;
  return [0, 1, 2, 3, 4, 5].map(i => {
    const yearString = (currentYear + i).toString();
    return { key: yearString, text: yearString, data: yearString };
  });
};

export const allMonths = (): IDropdownOption<string>[] => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
    const monthString = month < 10 ? `0${month.toString()}` : month.toString();
    return {
      key: monthString,
      text: monthString,
      data: monthString
    };
  });
};

export const irnNumbers = (): IDropdownOption<string>[] => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9].map(n => ({
    key: n.toString(),
    text: n.toString(),
    data: n.toString()
  }));
};
