import axios, { AxiosError } from "axios";

import { appConfig } from "../AppConfig";
import { FormStatusEnum } from "../hooks/useFormApi";

export const baseURL = `${appConfig.api.url}/api/v1/formInstances/`;
const postUrl = (id: string) => `${id}/responses`;

const api = axios.create({
  baseURL
});

function errorHandlingInterceptor(error: AxiosError) {
  let result = {};

  switch (error.response?.status) {
    case 410:
      result = {
        status: FormStatusEnum.Expired,
        description: "Sorry, the form has expired.",
        helpText: "Please contact the practice if you have any questions."
      };
      break;
    case 409:
      result = {
        status: FormStatusEnum.AlreadyCompleted,
        description: "The form has already been submitted.",
        helpText:
          "Please contact the practice if you have any questions or corrections to the submitted information."
      };
      break;
    case 400:
      result = {
        status: FormStatusEnum.Cancelled,
        description: "Sorry, the form is no longer available.",
        helpText: "Please contact the practice if you have any questions."
      };
      break;
    default:
      result = {
        status: FormStatusEnum.NotFound,
        description: "404. This page does not exist.",
        helpText: "Please contact the practice if you have any questions."
      };
  }
  return Promise.reject(result);
}
api.interceptors.response.use(undefined, errorHandlingInterceptor);

export { api, postUrl };
