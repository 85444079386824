import { Slider, Stack } from "@bps/fluent-ui";
import { Text } from "@fluentui/react";

export interface IRangePicker {
  onChange(value: number | undefined): void;
  value: number;
  min: number;
  max: number;
  minText?: string;
  maxText?: string;
  middleText?: string;
  step?: number;
}

export const CustomRangePicker: React.FC<IRangePicker> = props => (
  <Stack styles={{ root: { width: "100%" } }}>
    <Slider {...props} value={props.value || 0} />
    <Stack
      horizontal
      styles={{
        root: { justifyContent: "space-between", width: "100%" }
      }}
    >
      <Text>{props.minText ?? props.min}</Text>
      <Text>{props.middleText ?? props.value}</Text>
      <Text>{props.maxText ?? props.max}</Text>
    </Stack>
  </Stack>
);
