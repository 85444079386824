import * as React from "react";
import * as SurveyCore from "survey-core";
import * as SurveyReact from "survey-react-ui";

const { ElementFactory } = SurveyCore;
export const renderers = new Map<string, string>();
export const replaceWidget: (options: {
  questionName: string;
  renderer: typeof SurveyReact.SurveyQuestionElementBase;
  model: (name: string) => any;
  extraProperties?: any;
}) => void = ({ questionName, renderer, model, extraProperties }) => {
  const renderName = `${questionName}-bp`;
  ElementFactory.Instance.registerElement(renderName, name => {
    return model(name);
  });
  // Register the CustomSelect component as a renderer."
  SurveyReact.ReactQuestionFactory.Instance.registerQuestion(
    renderName,
    props => {
      return React.createElement(renderer, props);
    }
  );
  // Register ^^^ as a renderer for questions whose `type` is questionName and `renderAs` property is renderName
  SurveyCore.RendererFactory.Instance.registerRenderer(
    questionName,
    renderName,
    renderName
  );
  if (extraProperties) {
    SurveyCore.Serializer.addProperties(questionName, extraProperties);
    SurveyCore.Serializer.addClass(
      questionName,
      SurveyCore.Serializer.getProperties(questionName),
      function (json) {
        return model(JSON.stringify(json));
      },
      "question"
    );
  }
  // We use the renderers list when we load a form, and set the renderAs flag for each control we have a widget for.
  renderers.set(questionName, renderName);
};
