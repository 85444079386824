import { QuestionTextModel } from "survey-core";
import * as SurveyReact from "survey-react-ui";

import { MaskedTextField, TextField } from "@bps/fluent-ui";

import { CustomDatePicker } from "./CustomDatePicker";
import { CustomNumberPicker } from "./CustomNumberPicker";
import { CustomRangePicker } from "./CustomRangePicker";
import { replaceWidget } from "./replaceWidget";

class CustomText extends SurveyReact.SurveyQuestionElementBase {
  get question() {
    return this.questionBase as BpQuestionTextModel;
  }
  renderElement() {
    // If the question is non-editable, render a stylized div
    if (this.isDisplayMode) {
      return (
        <div
          id={this.question.inputId}
          className={this.question.getControlClass()}
        >
          {" "}
          {this.question.displayValue || this.question.placeholder}{" "}
        </div>
      );
    }

    switch (this.question.inputType) {
      case "date":
        return (
          <CustomDatePicker
            {...this.question}
            value={this.question.value}
            onChange={newValue => (this.question.value = newValue)}
            minDateString={this.question.renderedMin}
            maxDateString={this.question.renderedMax}
            startDate={this.question.startDate}
          />
        );
      case "number":
        return (
          <CustomNumberPicker
            {...this.question}
            value={
              this.question.value === undefined
                ? undefined
                : String(this.question.value)
            }
            onChange={newValue => (this.question.value = newValue)}
            min={this.question.min ? Number(this.question.min) : 0}
            max={this.question.max ? Number(this.question.max) : 10}
            step={Number(this.question.step) || 1}
          />
        );
      case "range":
        return (
          <CustomRangePicker
            {...this.question}
            value={this.question.value}
            onChange={newValue => (this.question.value = newValue)}
            min={this.question.min ? Number(this.question.min) : 0}
            max={this.question.max ? Number(this.question.max) : 100}
            minText={this.question.minText}
            maxText={this.question.maxText}
            middleText={this.question.middleText}
            step={Number(this.question.step) || 1}
          />
        );
      case "textupper":
        return (
          <TextField
            {...this.question}
            value={this.question.value}
            onChange={(_event, newValue) =>
              (this.question.value = newValue?.toUpperCase())
            }
            type={this.question.inputType}
            maxLength={this.question.maxLength}
            autoComplete="off"
            aria-autocomplete="none"
          />
        );
      default:
        return !this.question.inputMask ? (
          <TextField
            value={this.question.value}
            onChange={(_event, newValue) => (this.question.value = newValue)}
            type={this.question.inputType}
            maxLength={this.question.maxLength}
            autoComplete="off"
            aria-autocomplete="none"
          />
        ) : (
          <MaskedTextField
            value={this.question.value}
            onChange={(_event, newValue) => (this.question.value = newValue)}
            type={this.question.inputType}
            maxLength={this.question.maxLength}
            autoComplete="off"
            aria-autocomplete="none"
            mask={this.question.inputMask}
            maskChar={this.question.maskChar || " "}
          />
        );
    }
  }
}

export class BpQuestionTextModel extends QuestionTextModel {
  public get inputMask(): string {
    return this.getPropertyValue("inputMask");
  }
  public set inputMask(val: string) {
    this.setPropertyValue("inputMask", val);
  }
  public get maskChar(): string {
    return this.getPropertyValue("maskChar");
  }
  public set maskChar(val: string) {
    this.setPropertyValue("maskChar", val);
  }
  public set minText(val: string) {
    this.setPropertyValue("minText", val);
  }
  public get minText() {
    return this.getPropertyValue("minText");
  }
  public set maxText(val: string) {
    this.setPropertyValue("maxText", val);
  }
  public get maxText() {
    return this.getPropertyValue("maxText");
  }
  public set startDate(val: string) {
    this.setPropertyValue("startDate", val);
  }
  public get startDate() {
    return this.getPropertyValue("startDate");
  }
}

export const registerTextBoxWidget = () => {
  replaceWidget({
    questionName: "text",
    renderer: CustomText,
    model: name => new BpQuestionTextModel(name),
    extraProperties: [
      {
        name: "inputMask:string",
        category: "general",
        visibleIndex: 3
      },
      {
        name: "maskChar:string",
        category: "general",
        visibleIndex: 5
      },
      {
        name: "minText:string",
        category: "general",
        visibleIndex: 6
      },
      {
        name: "maxText:string",
        category: "general",
        visibleIndex: 7
      },
      {
        name: "middleText:string",
        category: "general",
        visibleIndex: 8
      },
      {
        name: "startDate:string",
        category: "general",
        visibleIndex: 9
      }
    ]
  });
};
