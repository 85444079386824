import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useAsync } from "react-use";
import { Model } from "survey-core";

import { getForm } from "../api/form";
import { setupModel } from "./setupModel";
import { useFormId } from "./useFormId";

export enum FormStatusEnum {
  Loading = "Loading",
  Available = "Available",
  Preview = "Preview",
  Initialising = "Initialising",
  NotFound = "Not Found",
  DecodeError = "Decode Error",
  AlreadyCompleted = "Already Completed",
  Expired = "Expired",
  Cancelled = "Cancelled",
  Completed = "Completed",
  QrCode = "QrCode"
}

export interface FormStatus {
  status: FormStatusEnum;
  description?: string;
  form?: Model;
  expiry?: DateTime;
  helpText?: string;
  testform?: boolean;
  titleFields?: Record<string, string>;
}

export const useFormApi = () => {
  const { formId, qrcode, preview } = useFormId();
  const [formStatus, setFormStatus] = useState<FormStatus>({
    status: FormStatusEnum.Initialising,
    form: undefined,
    titleFields: undefined
  });

  const setDeveloperPreviewForm = (form: string) => {
    setFormStatus({
      status: FormStatusEnum.Available,
      ...setupModel({ modelJson: form, setFormStatus, formId, qrcode, preview })
    });
  };

  useEffect(() => {
    setFormStatus({
      status: formId ? FormStatusEnum.Loading : FormStatusEnum.NotFound,
      description: "Incorrect Address",
      helpText: ""
    });
  }, [formId, qrcode]);

  const { loading, error, value } = useAsync(
    !formStatus.form &&
      formStatus.status !== FormStatusEnum.DecodeError &&
      formStatus.status !== FormStatusEnum.Completed
      ? () => getForm(formId)
      : Promise.resolve,
    [formId]
  );

  const data = value?.data;

  // The 'validators' property of form elements is passed from the backend as
  // a string, so it needs to be parsed.
  const formData = JSON.parse(data?.formData || "{}");
  formData?.pages?.forEach((page: any) => {
    page.elements?.forEach((element: any) => {
      if (element.validators && typeof element.validators === "string") {
        element.validators = JSON.parse(element.validators);
      }
    });
  });
  if (data) data.formData = JSON.stringify(formData);

  useEffect(() => {
    try {
      if (
        !loading &&
        !error &&
        data &&
        formStatus.status !== FormStatusEnum.Completed &&
        formStatus.status !== FormStatusEnum.QrCode
      ) {
        const expiry = DateTime.fromISO(data.expiryDate);
        formId &&
          data &&
          data.formData &&
          setupModel({
            modelJson: data.formData,
            expiry,
            formId,
            setFormStatus,
            formConfig: data.formConfiguration,
            qrcode,
            preview
          });
      } else if (error) {
        setFormStatus(error as unknown as FormStatus);
      }
    } catch (e) {
      setFormStatus({
        status: FormStatusEnum.DecodeError,
        description: "This form could not be decoded."
      });
    }
  }, [loading, data, formId, error, formStatus.status, qrcode, preview]);

  return { ...formStatus, setDeveloperPreviewForm };
};
