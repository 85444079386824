import { useEffect, useState } from "react";
import { ItemValue } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";

import { ButtonsGroupChoice } from "@bps/fluent-ui";
import { Label, Stack } from "@fluentui/react";

import { convertStringToOption, useFieldLookupApi } from "../hooks";
import { CustomSelectModel } from "./MultiTagBox";
import { replaceWidget } from "./replaceWidget";

export interface CustomRadioGroupProps {
  choices: ItemValue[];
  value: string;
  onSelected: (value: string[] | string | undefined) => void;
  fetchUrl: string;
}

export const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
  choices,
  value,
  onSelected,
  fetchUrl
}) => {
  const { data, error } = useFieldLookupApi(fetchUrl);
  const rows = data ? data : choices.map(x => convertStringToOption(x.value));

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  const isCollapsed = windowWidth < rows.length * 150;

  return (
    <Stack>
      {!error ? (
        <ButtonsGroupChoice
          styles={{
            label: {
              minHeight: "unset",
              marginLeft: isCollapsed ? "0px !important" : undefined,
              verticalAlign: "center"
            },
            root: {
              flexDirection: isCollapsed ? "column" : "row",
              margin: 0
            }
          }}
          equalWidth
          options={rows}
          value={value ? [value] : []}
          onChange={selectedKeys => onSelected(selectedKeys)}
        />
      ) : (
        <Label>Error loading</Label>
      )}
    </Stack>
  );
};
class CustomRadioGroupModel extends CustomSelectModel {
  getType() {
    return "radiogroup";
  }
}
class CustomRadioGroupQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = { value: this.question.value };
  }
  get question() {
    return this.questionBase as CustomRadioGroupModel;
  }
  get value() {
    return this.question.value;
  }
  visibleChoices: string[] = [];
  get choices() {
    return this.visibleChoices;
  }
  set choices(value: string[]) {
    this.visibleChoices = value;
  }
  renderElement() {
    return (
      <Stack
        styles={{ root: { minWidth: "50%", justifyContent: "space-between" } }}
      >
        {this.question.label && <Label>{this.question.label}</Label>}
        <CustomRadioGroup
          choices={this.question.choices}
          onSelected={(selected: string | string[] | undefined) => {
            if (typeof selected === "string") {
              this.question.value = selected;
              return;
            } else if (selected && selected.length) {
              this.question.value =
                selected && selected.length ? selected[0] : undefined;
            } else {
              this.question.value = undefined;
            }
          }}
          fetchUrl={this.question.fetchUrl}
          value={this.question.value}
        />
      </Stack>
    );
  }
}
export const registerRadioGroupWidget = () =>
  replaceWidget({
    questionName: "radiogroup",
    renderer: CustomRadioGroupQuestion,
    model: name => new CustomRadioGroupModel(name),
    extraProperties: [
      {
        name: "choices:itemValue[]",
        category: "general",
        visibleIndex: 3 // Place after the Name, Title, and Color Picker Type
      },
      {
        name: "fetchUrl:string",
        category: "general",
        visibleIndex: 4 // Place after the Name, Title, and Color Picker Type
      },
      {
        name: "label:string",
        category: "general",
        visibleIndex: 5 // Place after the Name, Title, and Color Picker Type
      }
    ]
  });
