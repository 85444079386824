import { DateTime } from "luxon";
import { FC } from "react";

import { Stack, Text } from "@bps/fluent-ui";

const getField = (field: string, titleFields: Record<string, string>) => {
  const titleKeys = titleFields && (Object.keys(titleFields) as string[]);
  const keyIndex = titleKeys && titleKeys.findIndex(x => x === field);
  if (typeof keyIndex === "number" && keyIndex >= 0) {
    const values = titleFields && (Object.values(titleFields) as string[]);
    return values && values[keyIndex];
  }
  return null;
};

interface FormHeaderProps {
  titleFields?: Record<string, string>;
  expiry?: DateTime;
}
export const FormHeader: FC<FormHeaderProps> = ({ titleFields, expiry }) => {
  const titles: string[] = [];
  if (titleFields) {
    const patientName = getField("PatientName", titleFields);
    patientName && titles.push(`Generated for : ${patientName}`);

    const startDate = getField("StartTime", titleFields);
    const practitioner = getField("Practitioner", titleFields);

    if (startDate && practitioner) {
      titles.push(`Appointment: on ${startDate} with ${practitioner}`);
    } else {
      if (startDate) {
        titles.push(`Appointment: on ${startDate} `);
      }
      if (practitioner) {
        titles.push(`Appointment: with ${practitioner}`);
      }
    }
    if (titles.length && expiry) {
      titles.push(`Expiring on: ${expiry?.toLocal().toFormat("dd LLLL yyyy")}`);
    }
  }
  const Expiry: FC = () =>
    expiry ? (
      <Text>
        This form expires on {expiry?.toLocal().toFormat("dd LLLL yyyy")}
      </Text>
    ) : null;

  return titles ? (
    <Stack>
      {titles.map(x => (
        <Text key={x}>{x}</Text>
      ))}
    </Stack>
  ) : (
    <Expiry />
  );
};
