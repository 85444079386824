import { FunctionComponent } from "react";

import { Text, Stack } from "@fluentui/react";

export interface InvalidRequestProps {
  description?: string;
  helpText?: string;
}

export const InvalidRequest: FunctionComponent<InvalidRequestProps> = ({
  description,
  helpText
}) => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      verticalFill
      styles={{
        root: {
          height: "100vh"
        }
      }}
    >
      <Text variant="xLarge" styles={{ root: { textAlign: "center" } }}>
        {description}
      </Text>
      <Text
        variant="medium"
        styles={{
          root: { textAlign: "center", paddingTop: 16, maxWidth: 348 }
        }}
      >
        {helpText}
      </Text>
    </Stack>
  );
};
