export const migration = `BEGIN TRANSACTION
	

	DECLARE @TenantId UNIQUEIDENTIFIER;  
	DECLARE @Country nvarchar(max);  
  
	DECLARE tenant_cursor CURSOR FOR  
	SELECT TenantId , Country FROM [dbo].[Tenant]   
	OPEN tenant_cursor;

	FETCH NEXT FROM tenant_cursor INTO @TenantId, @Country;

	-- Check @@FETCH_STATUS to see if there are any more rows to fetch.  
	WHILE @@FETCH_STATUS = 0  
	BEGIN 
		IF EXISTS(SELECT 1 FROM [Form].[FormTemplate] WHERE [TenantId] = @TenantId AND [Code] =  'PATDEMO')
		BEGIN
			IF (@Country = 'NZ')
			BEGIN 
				UPDATE [Form].[FormTemplate] SET
					TemplateData = '{{Personal details (NZ)}}',
					ContextJson = '["PatientId"]'
					WHERE Code= 'PATDEMO' AND 
						TenantId = @TenantId			

				UPDATE [Form].[FormTemplate] SET
					TemplateData = '{{ACC 45}}',
					ContextJson ='["PatientId","ClaimId"]'
					WHERE Code= 'ACC' AND 
						TenantId = @TenantId			

				UPDATE [Form].[FormTemplate] SET
					TemplateData = '{{Personal details and ACC 45}}',
					ContextJson ='["PatientId","ClaimId"]'
					WHERE Code= 'ACCPATDEMO' AND 
						TenantId = @TenantId			
			END
			IF (@Country != 'NZ') --AU Tenants
			BEGIN 
				UPDATE [Form].[FormTemplate] SET
					TemplateData = '{{Personal details (AU)}}',
					ContextJson ='["PatientId"]'
					WHERE Code= 'PATDEMO' AND 
						TenantId = @TenantId			
			END
		END	

		FETCH NEXT FROM tenant_cursor  INTO @TenantId, @Country;
	END	

	CLOSE tenant_cursor;  
	DEALLOCATE tenant_cursor; 
	GO

COMMIT TRANSACTION
`;
