import { QRCodeSVG } from "qrcode.react";
import { FC } from "react";

import { Stack, useScreenSize } from "@bps/fluent-ui";

import { useFormId } from "./hooks";

export const QrCode: FC = () => {
  const { preview } = useFormId();
  const { width, height } = useScreenSize();
  const min = Math.min(width, height);
  const codeSize = Math.floor(min * 0.7);
  const size = Math.max(codeSize, 128);
  const { formId } = useFormId();
  const { origin } = window.location;
  const previewURLPath = preview ? "/preview" : "";
  const href = `${origin}${previewURLPath}/?id=${formId}`;
  return (
    <Stack
      styles={{
        root: {
          width: "100%",
          marginTop: "5%",
          alignItems: "center",
          height: "100%"
        }
      }}
    >
      <QRCodeSVG value={href} size={size} />
    </Stack>
  );
};
