import * as React from "react";
import { SurveyQuestionElementBase } from "survey-react-ui";

import { CheckboxGroup, CheckboxGroupOption } from "@bps/fluent-ui";
import { Label } from "@fluentui/react";

import { useFieldLookupApi } from "../hooks";
import { CustomSelectModel } from "./MultiTagBox";
import { replaceWidget } from "./replaceWidget";

class CustomCheckboxesModel extends CustomSelectModel {
  getType() {
    return "checkbox";
  }

  get choices() {
    return this.getPropertyValue("choices");
  }

  set choices(val) {
    this.setPropertyValue("choices", val);
  }

  public get fetchUrl(): string {
    return this.getPropertyValue("fetchUrl");
  }

  public set fetchUrl(val: string) {
    this.setPropertyValue("fetchUrl", val);
  }
}

export interface CustomPickerProps {
  choices: CheckboxGroupOption<string>[];
  value: string[];
  onChange: (value: string[] | string) => void;
  fetchUrl: string;
}

export const CustomCheckboxes: React.FC<CustomPickerProps> = ({
  choices,
  value,
  onChange,
  fetchUrl
}) => {
  const { data, error } = useFieldLookupApi(fetchUrl);
  const rows = data ? data : choices;
  return (
    <>
      <CheckboxGroup
        disabled={false}
        onChange={selected => selected && onChange(selected)}
        options={rows}
        value={value ?? []}
      />

      {error && <Label>Error loading</Label>}
    </>
  );
};

class CustomCheckboxesQuestion extends SurveyQuestionElementBase {
  get question() {
    return this.questionBase as CustomCheckboxesModel;
  }

  constructor(props: any) {
    super(props);
    this.state = { value: this.question.value };
  }

  get value() {
    return this.question.value;
  }

  renderElement() {
    return (
      <CustomCheckboxes
        choices={this.question.choices.map((x: string) => ({
          value: x,
          label: x
        }))}
        onChange={(value?: string | string[]) => {
          this.question.value = value;
        }}
        fetchUrl={this.question.fetchUrl}
        value={this.question.value}
      />
    );
  }
}

export const replaceCheckboxesWidget = () =>
  replaceWidget({
    questionName: "checkbox",
    renderer: CustomCheckboxesQuestion,
    model: name => new CustomCheckboxesModel(name),
    extraProperties: [
      {
        name: "choices:string[]",
        category: "general",
        visibleIndex: 3 // Place after the Name, Title, and Color Picker Type
      },
      {
        name: "fetchUrl:string",
        category: "general",
        visibleIndex: 4 // Place after the Name, Title, and Color Picker Type
      },
      {
        name: "customListFunction:string",
        category: "general",
        visibleIndex: 5
      }
    ]
  });
