import { useState } from "react";

import { PrimaryButton, Dialog, Stack, Text } from "@bps/fluent-ui";

import acc from "./Forms/Current/ACC.json";
import accDemo from "./Forms/Current/ACCDEMO.json";
import demoAU from "./Forms/Current/DemographicsAu.json";
import demoNZ from "./Forms/Current/DemographicsNZ.json";
import { formTemplate } from "./SourceTemplates/FormTemplate.cs";
import { migration } from "./SourceTemplates/Migration.sql";

const MIGRATION_INSTRUCTIONS =
  "Create a new Migration in Bps.Titanium.Forms\\Bps.Titanium.Forms.Database\\Migrations\\ with the code currently in the clipboard. \r\n Delete the old migration that will now be un-nesessary.";
const CSHARP_INSTRUCTIONS =
  "Replace the code in \r\n Bps.Titanium.Forms\\Bps.Titanium.Forms.Business\\Entities\\FormTemplate.cs \r\n with the contents of the clipboard";

function csharpise(x: any) {
  return JSON.stringify(x)
    .replace(/\\/g, "\\\\")
    .replace(/"/g, '\\"')
    .replace(/\n/g, "");
}

function sqlise(x: any) {
  return JSON.stringify(x).replace(/'/g, "''");
}

export const ExportDialog: React.FC<{ onClose: () => void }> = ({
  onClose
}) => {
  const replacements = [acc, accDemo, demoAU, demoNZ];
  const cstemplate = replacements.reduce((p, v) => {
    return p.replace(`{{${v.name}}}`, csharpise(v));
  }, formTemplate);
  const sqltemplate = replacements.reduce((p, v) => {
    return p.replace(`{{${v.name}}}`, sqlise(v));
  }, migration);
  const [instructions, setInstructions] = useState<{
    cs?: string;
    sql?: string;
  }>({});

  return (
    <Dialog hidden={false}>
      <Stack>
        <Stack>
          <Text>Sql</Text>
          <PrimaryButton
            onClick={() => {
              navigator.clipboard.writeText(sqltemplate);
              setInstructions({ sql: MIGRATION_INSTRUCTIONS });
            }}
          >
            Copy SQL to clipboard
          </PrimaryButton>
          <Text>{instructions.sql}</Text>
        </Stack>
        <Stack>
          <Text>C#</Text>
          <PrimaryButton
            onClick={() => {
              navigator.clipboard.writeText(cstemplate);
              setInstructions({ cs: CSHARP_INSTRUCTIONS });
            }}
          >
            Copy C# to clipboard
          </PrimaryButton>
          <Text>{instructions.cs}</Text>
        </Stack>
      </Stack>

      <PrimaryButton onClick={onClose}>Close</PrimaryButton>
    </Dialog>
  );
};
