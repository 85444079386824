import acc from "./Current/ACC.json";
import accDemo from "./Current/ACCDEMO.json";
import demoAU from "./Current/DemographicsAu.json";
import demoNZ from "./Current/DemographicsNZ.json";
import allInput from "./Test/AllInput.json";
import lookup from "./Test/Lookup.json";
import medicare from "./Test/Medicare.json";
import minRequired from "./Test/MinRequired.json";
import work from "./Test/Work.json";

export const testModels: ({ title: string } | any)[] = process.env
  .REACT_APP_TEST
  ? [
      lookup,
      demoNZ,
      demoAU,
      accDemo,
      acc,
      medicare,
      work,
      allInput,
      minRequired
    ]
  : [];
