import { FC } from "react";
import { ItemValue, Question } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";

import { CustomCheckboxes } from "./Checkbox";
import { CustomDropDownControl } from "./DropDown";
import { MultiTagPicker } from "./MultiTagBox";
import { CustomRadioGroup } from "./RadioGroup";
import { registerWidget } from "./registerWidget";

const QUESTION_TYPE = "bpMultipleChoice";

export class CustomMultipleChoiceModel extends Question {
  constructor(props: string) {
    super(props);
    this.choices = [];
  }

  getType() {
    return QUESTION_TYPE;
  }

  get choices() {
    return this.getPropertyValue("choices");
  }

  set choices(val) {
    this.setPropertyValue("choices", val);
  }

  public get maxChoices(): number {
    return this.getPropertyValue("maxChoices");
  }

  public set maxChoices(val: number) {
    this.setPropertyValue("maxChoices", val);
  }
}

export interface CombinedMultipleChoiceFieldProps {
  choices?: string[];
  value?: string | string[];
  onChange: (value?: string | string[]) => void;
  maxChoices?: number;
}

const CombinedMultipleChoiceField: FC<CombinedMultipleChoiceFieldProps> = ({
  choices,
  value,
  onChange,
  maxChoices
}) => {
  const isMulti = maxChoices !== 1;
  const isLong = choices && choices.length > 5;

  if (isMulti && isLong) {
    return (
      <MultiTagPicker
        options={choices.map(text => ({ name: text, key: text }))}
        fetchUrl=""
        onSelectChange={onChange}
        value={(value as string[]) ?? []}
      />
    );
  } else if (isMulti) {
    return (
      <CustomCheckboxes
        fetchUrl=""
        choices={(choices ?? []).map(text => ({
          value: text,
          label: text
        }))}
        onChange={onChange}
        value={(value as string[]) ?? []}
      />
    );
  } else if (isLong) {
    return (
      <CustomDropDownControl
        choices={choices.map(text => ({ text, key: text }))}
        fetchUrl=""
        customListFunction=""
        isDisplayMode={false}
        onChange={onChange}
        value={(value as string) ?? ""}
      />
    );
  } else {
    return (
      <CustomRadioGroup
        choices={(choices ?? []).map(
          text =>
            ({ name: text, value: text, key: text } as unknown as ItemValue)
        )}
        fetchUrl=""
        onSelected={onChange}
        value={(value as string) ?? ""}
      />
    );
  }
};

class MultipleChoiceQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.question.getSelectBaseRootCss = () => "";
  }

  get question() {
    return this.questionBase as CustomMultipleChoiceModel;
  }

  onChange = (value?: string | string[]) => {
    this.question.value = value;
  };

  renderElement = () => (
    <CombinedMultipleChoiceField
      choices={this.question.choices}
      maxChoices={this.question.maxChoices}
      value={this.question.value}
      onChange={this.onChange}
    />
  );
}

export const registerCombinedMultipleChoice = () => {
  registerWidget({
    baseQuestion: QUESTION_TYPE,
    newQuestionName: QUESTION_TYPE,
    renderer: MultipleChoiceQuestion,
    newToolboxItem: {
      name: QUESTION_TYPE,
      title: "Multiple Choice",
      elementsJSON: {
        choices: [],
        value: [],
        visible: true
      }
    },
    newModelFn: name => new CustomMultipleChoiceModel(name), // TODO
    extraProperties: [
      {
        name: "choices:string[]",
        category: "general",
        visibleIndex: 3
      },
      {
        name: "maxChoices:number",
        category: "general",
        visibleIndex: 4
      },
      {
        name: "fetchUrl:string",
        category: "general",
        visibleIndex: 5
      }
    ]
  });
};
