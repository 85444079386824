import { api, postUrl } from "./base";

const postForm = async (formId: string, data: any) => {
  const url = postUrl(formId);
  return await api.post(url, { response: JSON.stringify(data) });
};

const getForm = async (formId: string): Promise<any> => {
  return api.get<any>(formId);
};

const getFormFieldData = async (
  formId: string,
  fieldId: string
): Promise<any> => {
  return api.get(`${formId},${fieldId}`);
};

export { getForm, postForm, getFormFieldData };
