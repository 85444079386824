import { useFieldApi } from "./useFieldApi";

export const convertStringToOption = (value: string) => ({
  value,
  key: value,
  text: value
});

export const useFieldLookupApi = (
  fieldName?: string,
  convert: (item: string) => any = convertStringToOption
) => {
  const { data, error, loading } = useFieldApi(fieldName);
  const decoded: string[] = data && (JSON.parse(data) as string[]);
  return { data: decoded?.map(convert), error, loading };
};
