import { DatePicker } from "@bps/fluent-ui";

export interface IDatePicker {
  onChange(value: string | undefined): void;
  value: string;
  minDateString?: string;
  maxDateString?: string;
  startDate?: string;
}
const getDateString = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() - offset * 60 * 1000);
  return newDate.toISOString().split("T")[0];
};
export const CustomDatePicker: React.FC<IDatePicker> = ({
  onChange,
  value,
  minDateString,
  maxDateString,
  startDate
}) => {
  const onDateChange = (value: Date | undefined) =>
    onChange(value ? getDateString(value) : undefined);
  const date = value ? new Date(value) : undefined;
  const minDate = minDateString ? new Date(minDateString) : undefined;
  const maxDate = maxDateString ? new Date(maxDateString) : undefined;
  const startDateParts = startDate?.split("/").map(val => Number.parseInt(val));

  return (
    <DatePicker
      value={date}
      onChange={onDateChange}
      calendarProps={{
        minDate,
        maxDate,
        today:
          startDateParts && startDateParts.length >= 3
            ? new Date(
                startDateParts[2],
                startDateParts[1] - 1,
                startDateParts[0]
              )
            : undefined
      }}
    />
  );
};
