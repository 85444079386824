import { BrowserRouter } from "react-router-dom";
import { StylesManager } from "survey-core";
import "survey-core/modern.min.css";

import { defaultTheme, initializeIcons, ThemeProvider } from "@bps/fluent-ui";

import { SurveyRenderer } from "./SurveyRenderer";
import "./fonts.css";

initializeIcons(
  "https://static2.sharepointonline.com/files/fabric/assets/icons/"
);

export const App = () => {
  const defaultThemeColors = StylesManager.ThemeColors["modern"];
  defaultThemeColors["$main-color"] = "var(--primary-color)";
  defaultThemeColors["$main-hover-color"] = "var(--primary-color)";
  defaultThemeColors["$text-color"] = "var(--primary-text-color)";
  defaultThemeColors["$header-color"] = "var(--primary-color)";
  defaultThemeColors["$header-background-color"] = "var(--primary-bg-color)";

  defaultThemeColors["$focus-color"] = "var(--primary-color)";
  defaultThemeColors["$hover-color"] = "var(--primary-color)";

  StylesManager.applyTheme("modern");

  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <SurveyRenderer />
      </ThemeProvider>
    </BrowserRouter>
  );
};
