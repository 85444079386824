import { FunctionFactory } from "survey-core";

import { allMonths, medicareExpiryYears, irnNumbers } from "./medicareOptions";
import { isValidMedicareNumber } from "./validateMedicare";
import { checkNhiValues } from "./validateNHI";

export const setupValidators: () => void = () => {
  FunctionFactory.Instance.register("checkNhiValues", checkNhiValues);
  FunctionFactory.Instance.register(
    "isValidMedicareNumber",
    isValidMedicareNumber
  );
};

export const setupCustomListFunctions: () => void = () => {
  FunctionFactory.Instance.register("medicareExpiryYears", medicareExpiryYears);
  FunctionFactory.Instance.register("allMonths", allMonths);
  FunctionFactory.Instance.register("irnNumbers", irnNumbers);
};
