import { ItemValue, QuestionDropdownModel } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";

export class BpQuestionDropdownModel extends QuestionDropdownModel {
  public get customListFunction(): string {
    return this.getPropertyValue("customListFunction");
  }
  public set customListFunction(val: string) {
    this.setPropertyValue("customListFunction", val);
  }
  get loading() {
    return this.isLoadingFromJsonValue;
  }
  public get fetchUrl(): string {
    return this.getPropertyValue("fetchUrl");
  }
  public set fetchUrl(val: string) {
    this.setPropertyValue("fetchUrl", val);
  }
  public get choices() {
    return this.getChoices();
  }
}
export class BpQuestionChoiceBase extends SurveyQuestionElementBase {
  get question() {
    return this.questionBase as BpQuestionDropdownModel;
  }
  get choices(): ItemValue[] {
    const choices = this.question.choices;
    return choices;
  }
}
