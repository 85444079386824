export const formTemplate = `using System.Collections.Generic;

namespace Bps.Titanium.Forms.Business.Entities
{
    public partial class FormTemplate
    {
        public static IEnumerable<FormTemplate> DefaultData(string tenant)
        {
            var accFormTemplate = new FormTemplate
            {
                Code = FormTemplate.ACC,
                Name = "ACC 45 claim details",
                ContextJson = new List<string> { "PatientId", "ClaimId" },
                TemplateData = "{{ACC 45}}"
            };

            var accPatDemoFormTemplate = new FormTemplate
            {
                Code = FormTemplate.ACCPATDEMO,
                Name = "Personal + ACC 45 details",
                ContextJson = new List<string> { "PatientId", "ClaimId" },
                TemplateData = "{{Personal details and ACC 45}}"
            };

            var demoTemplateAu = new FormTemplate
            {
                Code = FormTemplate.PATDEMO,
                Name = "Personal details",
                ContextJson = new List<string> { "PatientId" },
                TemplateData = "{{Personal details (AU)}}"
            };

            var demoTemplateNz = new FormTemplate
            {
                Code = FormTemplate.PATDEMO,
                Name = "Personal details",
                ContextJson = new List<string> { "PatientId" },
                TemplateData = "{{Personal details (NZ)}}"
            };

            return new List<FormTemplate>
            {
                accFormTemplate,
                accPatDemoFormTemplate,
                tenant == "AU"? demoTemplateAu:demoTemplateNz

            };
        }
    }
}`;
